<!--
 * @Author: wxy
 * @Date: 2022-10-21 09:28:57
 * @LastEditTime: 2022-10-28 09:21:06
 * @Description: 添加供应商
-->
<template>
  <div>
    <div class="header">
      <ds-header :title="headerTitle" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :rules="rules">
      <a-form-model-item label="供应商名称" prop="supplierName">
        <a-input
          style="width: 800px"
          placeholder="请输入供应商名称，不超过50个字符"
          :max-length="50"
          v-model.trim="form.supplierName"
        />
      </a-form-model-item>
      <a-form-model-item label="供应商适用项目" prop="projectIdList">
        <!-- <a-select
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择供应商适用项目"
          v-model="form.projectIdList"
        >
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <el-cascader v-model="form.projectIdList"
              ref="cascader"
              style="width: 800px"
              :options="projectList"
              :collapse-tags = "true"
              :props="{emitPath:false, multiple: true,checkStrictly:true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
        </el-cascader>
      </a-form-model-item>
      <a-form-model-item label="供应商LOGO" prop="supplierLogo">
        <a-upload
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="IMG_API + '/oss/files'"
          :before-upload="beforeUploadLogo"
          @change="handleChangeLogo"
          accept=".png,.jpg,.jpeg"
        >
          <img class="upImg" v-if="form.supplierLogo" :src="form.supplierLogo" alt="avatar" />
          <div v-else>
            <a-icon type="plus" />
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="tips-color">图片类型支持jpg/png/jpeg类型，大小不超过1MB，比例1:1</div>
      <a-form-model-item label="供应商简介" :span="24" required>
        <div class="content">
          <div id="editer" style="width: 800px"></div>
        </div>
      </a-form-model-item>
      <div v-for="(item, index) in form.supplierLabelList">
        <a-form-model-item
          :label="index === 0 ? '供应商分类' : ' '"
          :colon="index == 0 ? true : false"
          :span="24"
          placeholder="请输入供应商分类，不超过5个字符"
          :prop="'supplierLabelList.' + index + '.productLabel'"
          :rules="{
            message: '请输入供应商分类，不超过5个字符',
            trigger: 'change',
          }"
        >
          <div
            style="
              display: flex;
              justify-content: flex-start;
            "
          >
            <a-input
              type="text"
              v-model="item.productLabel"
              :maxLength="5"
              placeholder="请输入供应商分类，不超过5个字符"
              style="width: 800px"
              allowClear
            ></a-input
            >&nbsp;&nbsp;
            <a-button
              type="primary"
              @click="deleteLabel(index)"
              v-if="form.supplierLabelList.length > 1"
              >删除</a-button
            >
          </div>
        </a-form-model-item>
      </div>
      <a-form-model-item  label=" " :colon="false" :span="24">
        <a-button
          type="primary"
          @click="addLabel"
          :disabled="form.supplierLabelList.length == 2 ? true : false"
        >
          <!-- <a-icon type="plus"/> -->添加供应商分类
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="状态" :span="24" prop="supplerState">
        <a-radio-group v-model="form.supplerState">
          <a-radio :value="0"> 启用 </a-radio>
          <a-radio :value="1"> 禁用 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
        <a-button type="primary" @click="submit" style="margin-right: 20px"> 确定 </a-button>
        <a-button type="info" @click="goBack"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
import debounce from "lodash/debounce";
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
import { forEach } from "lodash";
export default {
  name: "addSupplier",
  data() {
    return {
      IMG_API,
      // 标题
      headerTitle: "",
      form: {
        // 工应商名称
        supplierName: "",
        // 供应商状态
        supplerState: 0,
        // 供应商简介
        supplierContent: "",
        // 所属项目
        projectIdList: undefined,
        // 服务展示图片
        serverTypeImage: "",
        // 供应商LOGO
        supplierLogo: "",
        supplierLabelList:[{}]
      },
      rules: {
        supplierName: [{ required: true, message: "请输入供应商名称", trigger: "blur" }],
        supplerState: [{ required: true, message: "请选择状态", trigger: "change" }],
        projectIdList: [{ required: true, message: "请选择所属项目", trigger: "change" }],
        supplierLogo: [{ required: true, message: "请上传供应商LOGO", trigger: "change" }],
      },
      // 项目
      projectList: [],
    };
  },
  created() {
    // 获取所属项目
    this.getProject();
    if (this.$route.query.type === "edit") {
      this.headerTitle = "编辑供应商";
      this.getDetailList();
    } else {
      this.headerTitle = "新增供应商";
    }
  },
  mounted() {
    const _this = this;
    const editor = new wangEditor(`#editer`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.supplierContent = newHtml;
    };
    editor.config.menus = [
      "fontSize",
      "foreColor",
      "backColor",
      "underline",
      "italic",
      "bold",
      "justify",
      "splitLine",
      "undo",
      "redo",
      "list",
      "table",
      "image",
    ];
    editor.config.showFullScreen = false;
    editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgShowBase64 = false;
    editor.config.showLinkImg = false;
    editor.config.showLinkVideo = false;
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = "file";
    editor.config.uploadVideoName = "file";
    editor.config.uploadVideoTimeout = 60000;
    editor.config.uploadImgTimeout = 60000;
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function (xhr, editor, files) {
        console.log(xhr);
        console.log(editor);
        console.log(files);
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        let videoHTML =
          '&nbsp;<video poster="' +
          result.redirect_uri +
          '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
          result.redirect_uri +
          '" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do("insertHTML", videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      },
    };
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function (xhr) {},
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri);
      },
    };
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case "success":
          _this.$message.success(s);
          break;
        case "info":
          _this.$message.info(s);
          break;
        case "warning":
          _this.$message.warning(s);
          break;
        case "error":
          _this.$message.error(s);
          break;
        default:
          _this.$message.info(s);
          break;
      }
    };
    editor.config.placeholder = "请输入供应商简介";
    // 创建编辑器
    editor.create();
    this.editor = editor;
  },
  methods: {
    // 获取详情
    async getDetailList() {
      try {
        const res = await api.supplierDetail({ supplierId: this.$route.query.id });
        if (res.code === "200") {
          this.form = res.data;
          let arr = []
          if(res.data.supplierLabelList){
            for (let i = 0; i < res.data.supplierLabelList.length; i++) {
              const item = res.data.supplierLabelList[i];
              arr.push({productLabel:item})
            }
          }else{
            arr.push({productLabel:''})
          }
          console.log(arr);
          this.form.supplierLabelList = arr
          this.editor.txt.html(this.form.supplierContent);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确定
    submit: debounce(function () {
      let obj = {
        ...this.form,
      };
      let arr = []
      obj.supplierLabelList.forEach(item => {
        if(item.productLabel){
          arr.push(item.productLabel) 
        }
      });
      obj.supplierLabelList = arr
      if (obj.supplierContent === "") {
        this.$message.error("请填写供应商简介");
        return;
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.$route.query.id) {
            const res = await api.editSupplier(obj);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            const res = await api.addSupplier(obj);
            if (res.code === "200") {
              this.$message.success("添加成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }
        }
      });
    }, 300),
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 上传LOGO类型校验
    beforeUploadLogo(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png、jpeg格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (!isLt1M) {
        this.$message.error("图片最大1MB");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt1M && isSize;
    },
    // LOGO上传完成
    handleChangeLogo(info) {
      if (info.file.status === "done") {
        this.form.supplierLogo = info.file.response.redirect_uri;
      }
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject({check_type:true});
        if (res.code === "200") {
          this.projectList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    addLabel(){
      this.form.supplierLabelList.push({})
    },
    deleteLabel(index) {
      this.form.supplierLabelList.splice(index, 1)
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}

::v-deep .w-e-toolbar {
  z-index: 3 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.upImg {
  width: 109px;
  height: 109px;
}
.tips-color{
  margin-left: 200px;
  margin-bottom: 20px;
  margin-top: -20px;
}
</style>